import styled from 'styled-components';
import React, { useState } from 'react';
import { device } from '../utils/breakpoints';
import Link from './Link';
import Ametnes from '../images/Ametnes.svg';
import { Container, Navbar, Nav, Form } from 'react-bootstrap';
import * as classes from '../custom-styles/Header.module.css';
import { StaticImage } from 'gatsby-plugin-image'
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';

const SigninButton = styled.div`
background-color: #126601 !important;
color: black !important;
padding: 10px 25px !important;
border-radius: 10px;
color: white  !important;
font-size: 18px !important;
font-weight: 500;
& a{
  color: white;
}
&:hover a{
  color: white;
}
@media ${device.tablet} {
  padding: 10px 15px !important;
  font-size:14px !important;
}
@media ${device.phoneWide} {
  padding: 10px 25px !important;
  font-size: 18px !important;
}
`;

const SignupButton = styled.div`
background-color: #4075ff !important;
padding: 10px 25px !important;
border-radius: 10px;
color: white !important;
font-size: 18px !important;
font-weight: 500;
margin-left: 20px;
& a{
  color: white;
}
&:hover a{
  color: white;
}
@media ${device.tablet} {
  padding: 10px 15px !important;
  font-size:14px !important;
}
@media ${device.phoneWide} {
  padding: 10px 25px !important;
  font-size: 18px !important;
}
`;

const NavLinkPages = styled.div`
  color: #052475 !important;
  display:flex !important;
  padding: 0 15px;
  font-weight: 600;
  & a{
    color: #052475;
  }
  &:hover a{
    color: #052475;
  }
  @media ${device.phoneWide} {
    padding:40px 0;
  }
`;

const HeaderComponent = styled.div`
border-bottom: 1px solid #dee2e6;
padding: 12px 0;
@media ${device.tablet} {
  display: none;
}

`;

const LinkAdd = styled.div`
  padding:0 10px
`;

const Datanavbar = styled.div`
  display: flex;
  align-items: baseline;
  // width: 70%;
  justify-content: space-around;
`;

const NavContainer = styled.div`
  display:flex;
  width:100% !important;
  margin:auto;
  align-items: baseline;
  justify-content: space-between;

  @media ${device.desktop} {
    width:100%;
    // padding:0 30px;
  }
`;
const NavButton = styled.div`

`;

const HeaderComponentsecond = styled.div`
  display:none;
  border-bottom: 1px solid #dee2e6;
  padding: 12px 0;
  @media ${device.tablet} {
    display: block;
  }
`;

const TEST_DOCUMENT_URL = "https://test.cloud.ametnes.com/docs/"
const PROD_DOCUMENT_URL = "https://cloud.ametnes.com/docs/"

function Header() {

  const [documentURL, setDocumentURL] = useState()

  useEffect(() => {
    if (window?.location && window.location.hostname) {
      const hostname = window.location.hostname
      const url = hostname.includes("test") ? TEST_DOCUMENT_URL : PROD_DOCUMENT_URL
      setDocumentURL(url)
    }
  }, [])

  return (
    <>
      <HeaderComponent>
        <Container>
          <Navbar bg="whate" expand="lg">
            <NavContainer>
              <Link to={"/"}>
                <StaticImage src={'../images/AmetnesLogo.png'} height={45} alt='Ametnes Logo' />
              </Link>
              <Nav
                className=" my-2 my-lg-0 position-relative"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <NavLinkPages>
                  <Link to={'/platform'} className={`${classes.navlink}`}>
                    Platform
                  </Link>
                  <LinkAdd className="">|</LinkAdd>
                  <Link to={'/solutions'} className={`${classes.navlink}`}>
                    Solutions
                  </Link>
                  <LinkAdd className="">|</LinkAdd>
                  <Link to={'/services'} className={`${classes.navlink}`}>
                    Services
                  </Link>
                  <LinkAdd className="">|</LinkAdd>
                  <Link
                    to={`${'https://cloud.ametnes.com/docs/'}`}
                    target="_blank"
                    className={`${classes.navlink}`}
                  >
                    Resources
                  </Link>
                </NavLinkPages>
              </Nav>
              <Datanavbar>
                <NavButton>
                  <Form className="d-flex m-0">
                    <SigninButton>
                      <Link to={"/console/signin"}>Sign In</Link>
                    </SigninButton>
                    <SignupButton>
                      <Link to={"/console/signup"}>Sign Up</Link>
                    </SignupButton>
                  </Form>
                </NavButton>
              </Datanavbar>
            </NavContainer>
          </Navbar>
        </Container>
      </HeaderComponent>

      <HeaderComponentsecond>
        <Navbar expand="lg">
          <Container fluid>
            <Link to={"/"}>
              <StaticImage src={'../images/AmetnesLogo.png'} height={45} alt='Ametnes Logo' />
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <div>
                <Nav
                  className="m-auto my-2 my-lg-0 position-relative"
                  navbarScroll
                >
                  <NavLinkPages>
                    <Link to={'/platform'} className={`${classes.navlink}`}>
                      Platform
                    </Link>
                    <LinkAdd className="">|</LinkAdd>
                    <Link to={'/solutions'} className={`${classes.navlink}`}>
                      Solutions
                    </Link>
                    <LinkAdd className="">|</LinkAdd>
                    <Link to={'/services'} className={`${classes.navlink}`}>
                      Services
                    </Link>
                    <LinkAdd className="">|</LinkAdd>
                    <Link
                      to={'https://cloud.ametnes.com/docs/'}
                      target="_blank"
                      className={`${classes.navlink}`}
                    >
                      Resources
                    </Link>
                  </NavLinkPages>
                </Nav>
              </div>
              <NavButton>
                <Form className="d-flex m-0">
                  <SigninButton>
                    <Link to={"/console/signin"}>Sign In</Link>
                  </SigninButton>
                  <SignupButton>
                    <Link to={"/console/signup"}>Sign Up</Link>
                  </SignupButton>
                </Form>
              </NavButton>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </HeaderComponentsecond>
    </>
  );
}

export default Header
