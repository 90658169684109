// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nesis-body-jsx": () => import("./../../../src/pages/nesis/body.jsx" /* webpackChunkName: "component---src-pages-nesis-body-jsx" */),
  "component---src-pages-nesis-header-jsx": () => import("./../../../src/pages/nesis/header.jsx" /* webpackChunkName: "component---src-pages-nesis-header-jsx" */),
  "component---src-pages-nesis-index-js": () => import("./../../../src/pages/nesis/index.js" /* webpackChunkName: "component---src-pages-nesis-index-js" */),
  "component---src-pages-nesis-qanda-jsx": () => import("./../../../src/pages/nesis/qanda.jsx" /* webpackChunkName: "component---src-pages-nesis-qanda-jsx" */),
  "component---src-pages-platform-body-jsx": () => import("./../../../src/pages/platform/body.jsx" /* webpackChunkName: "component---src-pages-platform-body-jsx" */),
  "component---src-pages-platform-header-jsx": () => import("./../../../src/pages/platform/header.jsx" /* webpackChunkName: "component---src-pages-platform-header-jsx" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-platform-solutions-jsx": () => import("./../../../src/pages/platform/solutions.jsx" /* webpackChunkName: "component---src-pages-platform-solutions-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-services-body-jsx": () => import("./../../../src/pages/services/body.jsx" /* webpackChunkName: "component---src-pages-services-body-jsx" */),
  "component---src-pages-services-header-jsx": () => import("./../../../src/pages/services/header.jsx" /* webpackChunkName: "component---src-pages-services-header-jsx" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-qanda-jsx": () => import("./../../../src/pages/services/qanda.jsx" /* webpackChunkName: "component---src-pages-services-qanda-jsx" */),
  "component---src-pages-solutions-ai-body-item-jsx": () => import("./../../../src/pages/solutions/ai/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-ai-body-item-jsx" */),
  "component---src-pages-solutions-ai-body-jsx": () => import("./../../../src/pages/solutions/ai/body.jsx" /* webpackChunkName: "component---src-pages-solutions-ai-body-jsx" */),
  "component---src-pages-solutions-ai-header-jsx": () => import("./../../../src/pages/solutions/ai/header.jsx" /* webpackChunkName: "component---src-pages-solutions-ai-header-jsx" */),
  "component---src-pages-solutions-ai-index-js": () => import("./../../../src/pages/solutions/ai/index.js" /* webpackChunkName: "component---src-pages-solutions-ai-index-js" */),
  "component---src-pages-solutions-automation-body-item-jsx": () => import("./../../../src/pages/solutions/automation/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-automation-body-item-jsx" */),
  "component---src-pages-solutions-automation-body-jsx": () => import("./../../../src/pages/solutions/automation/body.jsx" /* webpackChunkName: "component---src-pages-solutions-automation-body-jsx" */),
  "component---src-pages-solutions-automation-header-jsx": () => import("./../../../src/pages/solutions/automation/header.jsx" /* webpackChunkName: "component---src-pages-solutions-automation-header-jsx" */),
  "component---src-pages-solutions-automation-index-js": () => import("./../../../src/pages/solutions/automation/index.js" /* webpackChunkName: "component---src-pages-solutions-automation-index-js" */),
  "component---src-pages-solutions-body-jsx": () => import("./../../../src/pages/solutions/body.jsx" /* webpackChunkName: "component---src-pages-solutions-body-jsx" */),
  "component---src-pages-solutions-card-jsx": () => import("./../../../src/pages/solutions/card.jsx" /* webpackChunkName: "component---src-pages-solutions-card-jsx" */),
  "component---src-pages-solutions-data-body-item-jsx": () => import("./../../../src/pages/solutions/data/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-data-body-item-jsx" */),
  "component---src-pages-solutions-data-body-jsx": () => import("./../../../src/pages/solutions/data/body.jsx" /* webpackChunkName: "component---src-pages-solutions-data-body-jsx" */),
  "component---src-pages-solutions-data-header-jsx": () => import("./../../../src/pages/solutions/data/header.jsx" /* webpackChunkName: "component---src-pages-solutions-data-header-jsx" */),
  "component---src-pages-solutions-data-index-js": () => import("./../../../src/pages/solutions/data/index.js" /* webpackChunkName: "component---src-pages-solutions-data-index-js" */),
  "component---src-pages-solutions-devops-body-item-jsx": () => import("./../../../src/pages/solutions/devops/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-devops-body-item-jsx" */),
  "component---src-pages-solutions-devops-body-jsx": () => import("./../../../src/pages/solutions/devops/body.jsx" /* webpackChunkName: "component---src-pages-solutions-devops-body-jsx" */),
  "component---src-pages-solutions-devops-header-jsx": () => import("./../../../src/pages/solutions/devops/header.jsx" /* webpackChunkName: "component---src-pages-solutions-devops-header-jsx" */),
  "component---src-pages-solutions-devops-index-js": () => import("./../../../src/pages/solutions/devops/index.js" /* webpackChunkName: "component---src-pages-solutions-devops-index-js" */),
  "component---src-pages-solutions-education-body-item-jsx": () => import("./../../../src/pages/solutions/education/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-education-body-item-jsx" */),
  "component---src-pages-solutions-education-body-jsx": () => import("./../../../src/pages/solutions/education/body.jsx" /* webpackChunkName: "component---src-pages-solutions-education-body-jsx" */),
  "component---src-pages-solutions-education-header-jsx": () => import("./../../../src/pages/solutions/education/header.jsx" /* webpackChunkName: "component---src-pages-solutions-education-header-jsx" */),
  "component---src-pages-solutions-education-index-js": () => import("./../../../src/pages/solutions/education/index.js" /* webpackChunkName: "component---src-pages-solutions-education-index-js" */),
  "component---src-pages-solutions-enterprise-body-item-jsx": () => import("./../../../src/pages/solutions/enterprise/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-enterprise-body-item-jsx" */),
  "component---src-pages-solutions-enterprise-body-jsx": () => import("./../../../src/pages/solutions/enterprise/body.jsx" /* webpackChunkName: "component---src-pages-solutions-enterprise-body-jsx" */),
  "component---src-pages-solutions-enterprise-header-jsx": () => import("./../../../src/pages/solutions/enterprise/header.jsx" /* webpackChunkName: "component---src-pages-solutions-enterprise-header-jsx" */),
  "component---src-pages-solutions-enterprise-index-js": () => import("./../../../src/pages/solutions/enterprise/index.js" /* webpackChunkName: "component---src-pages-solutions-enterprise-index-js" */),
  "component---src-pages-solutions-finance-body-item-jsx": () => import("./../../../src/pages/solutions/finance/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-finance-body-item-jsx" */),
  "component---src-pages-solutions-finance-body-jsx": () => import("./../../../src/pages/solutions/finance/body.jsx" /* webpackChunkName: "component---src-pages-solutions-finance-body-jsx" */),
  "component---src-pages-solutions-finance-header-jsx": () => import("./../../../src/pages/solutions/finance/header.jsx" /* webpackChunkName: "component---src-pages-solutions-finance-header-jsx" */),
  "component---src-pages-solutions-finance-index-js": () => import("./../../../src/pages/solutions/finance/index.js" /* webpackChunkName: "component---src-pages-solutions-finance-index-js" */),
  "component---src-pages-solutions-header-jsx": () => import("./../../../src/pages/solutions/header.jsx" /* webpackChunkName: "component---src-pages-solutions-header-jsx" */),
  "component---src-pages-solutions-healthcare-body-item-jsx": () => import("./../../../src/pages/solutions/healthcare/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-healthcare-body-item-jsx" */),
  "component---src-pages-solutions-healthcare-body-jsx": () => import("./../../../src/pages/solutions/healthcare/body.jsx" /* webpackChunkName: "component---src-pages-solutions-healthcare-body-jsx" */),
  "component---src-pages-solutions-healthcare-header-jsx": () => import("./../../../src/pages/solutions/healthcare/header.jsx" /* webpackChunkName: "component---src-pages-solutions-healthcare-header-jsx" */),
  "component---src-pages-solutions-healthcare-index-js": () => import("./../../../src/pages/solutions/healthcare/index.js" /* webpackChunkName: "component---src-pages-solutions-healthcare-index-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-sme-body-item-jsx": () => import("./../../../src/pages/solutions/sme/body-item.jsx" /* webpackChunkName: "component---src-pages-solutions-sme-body-item-jsx" */),
  "component---src-pages-solutions-sme-body-jsx": () => import("./../../../src/pages/solutions/sme/body.jsx" /* webpackChunkName: "component---src-pages-solutions-sme-body-jsx" */),
  "component---src-pages-solutions-sme-header-jsx": () => import("./../../../src/pages/solutions/sme/header.jsx" /* webpackChunkName: "component---src-pages-solutions-sme-header-jsx" */),
  "component---src-pages-solutions-sme-index-js": () => import("./../../../src/pages/solutions/sme/index.js" /* webpackChunkName: "component---src-pages-solutions-sme-index-js" */)
}

